import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Typography, Box, Card, CardContent, Button, Grid, Fab, Menu, MenuItem, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const Game = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [spinningWheels, setSpinningWheels] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const fetchSpinningWheels = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${auth.token}`
          }
        };
        const response = await axios.get('/api/games/spinning-wheels', config);
        setSpinningWheels(response.data);
      } catch (error) {
        console.error('Error fetching spinning wheels:', error.response ? error.response.data : error.message);
      }
    };

    fetchSpinningWheels();
  }, [auth.token]);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCreateGame = (gameType) => {
    handleCloseMenu();
    if (gameType === 'spinning-wheel') {
      navigate('/admin/games/spinning-wheel');
    }
    // Add more game types here if needed
  };

  const handleEdit = (id) => {
    navigate(`/admin/games/spinning-wheel/${id}`);
  };

  const copyToClipboard = (link) => {
    navigator.clipboard.writeText(link).then(() => {
      alert('Link copied to clipboard!');
    }).catch((error) => {
      console.error('Error copying text:', error);
    });
  };

  return (
    <Container>
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Typography variant="h4" gutterBottom>Games</Typography>
        <Grid container spacing={4}>
          {spinningWheels.map((wheel) => (
            <Grid item key={wheel._id} xs={12} sm={6} md={4}>
              <Card sx={{ height: '100%' }}>
                <CardContent>
                  <Typography variant="h5" component="h2">
                    Spinning Wheel for {wheel.eventId.title}
                  </Typography>
                  <Typography variant="body1">
                    Event: {wheel.eventId.title}
                  </Typography>
                  <Typography variant="body2">
                    Date: {new Date(wheel.eventId.startDate).toLocaleString()}
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <Button 
                      variant="contained" 
                      color="primary" 
                      href={`/game/spinning-wheel/${wheel._id}`} 
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ mr: 2 }}
                    >
                      Play Game
                    </Button>
                    <IconButton onClick={() => handleEdit(wheel._id)} color="secondary" sx={{ mr: 1 }}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => copyToClipboard(`${window.location.origin}/game/spinning-wheel/${wheel._id}`)} color="primary">
                      <ContentCopyIcon />
                    </IconButton>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Floating Action Button with Dropdown */}
      <Fab 
        color="primary" 
        aria-label="add" 
        onClick={handleOpenMenu} 
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        <AddIcon />
      </Fab>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={() => handleCreateGame('spinning-wheel')}>Spinning Wheel</MenuItem>
        {/* Add more game types here if needed */}
      </Menu>
    </Container>
  );
};

export default Game;
