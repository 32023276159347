import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TextField, Autocomplete, Box, ListItem, ListItemAvatar, Avatar, ListItemText, Typography, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import './SearchBar.css';

const SearchBar = () => {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (query.length > 1) {
      const fetchSuggestions = async () => {
        try {
          const response = await axios.get(`/api/events/search?q=${query}`);
          setSuggestions(response.data);
        } catch (error) {
          console.error('Error fetching suggestions:', error.response ? error.response.data : error.message);
        }
      };

      fetchSuggestions();
    } else {
      setSuggestions([]);
    }
  }, [query]);

  const handleSelect = (event, value) => {
    if (value) {
      navigate(`/event/${value._id}`);
    }
  };

  const handleSearchClick = () => {
    if (query) {
      navigate(`/search?q=${query}`);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && query) {
      navigate(`/search?q=${query}`);
    }
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 800, mx: 'auto', display: 'flex', alignItems: 'center' }} className="search-bar">
      <Autocomplete
        freeSolo
        options={suggestions}
        getOptionLabel={(option) => option.title}
        onInputChange={(event, newInputValue) => setQuery(newInputValue)}
        onChange={handleSelect}
        renderOption={(props, option) => (
          <ListItem {...props} alignItems="flex-start">
            <ListItemAvatar>
              <Avatar
                variant="square"
                src={`https://api.lericardo.pt/uploads/${option.coverImage}`}
                alt={option.title}
                sx={{ width: 56, height: 56, marginRight: 2 }}
              />
            </ListItemAvatar>
            <ListItemText
              primary={option.title}
              secondary={
                <>
                  <Typography component="span" variant="body2" color="textSecondary">
                    {new Date(option.startDate).toLocaleString()}
                  </Typography>
                  <br />
                  <Typography component="span" variant="body2" color="textSecondary">
                    {option.location}
                  </Typography>
                </>
              }
            />
          </ListItem>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search Events"
            onKeyDown={handleKeyDown}
            variant="outlined"
            fullWidth
          />
        )}
        sx={{ flexGrow: 1 }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSearchClick}
        sx={{ ml: 2, height: '56px' }} // Ensure button height matches the input field
      >
        <SearchIcon />
      </Button>
    </Box>
  );
};

export default SearchBar;
