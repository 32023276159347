import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { Button, Table, TableBody, TableCell, TableHead, TableRow, Paper, Container } from '@mui/material';

const EventList = () => {
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();
  const { auth } = useAuth();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${auth.token}`
          }
        };
        const response = await axios.get('/api/admin/events', config);
        setEvents(response.data);
      } catch (error) {
        console.error('Error fetching events:', error.response ? error.response.data : error.message);
      }
    };

    fetchEvents();
  }, [auth.token]);

  return (
    <Container component={Paper}>
      <Button variant="contained" color="primary" onClick={() => navigate('/admin/events/create')} sx={{ mt: 2, mb: 2 }}>
        Create Event
      </Button>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Location</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>End Date</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {events.map((event) => (
            <TableRow key={event._id}>
              <TableCell>{event.title}</TableCell>
              <TableCell>{event.description}</TableCell>
              <TableCell>{event.location}</TableCell>
              <TableCell>{new Date(event.startDate).toLocaleString()}</TableCell>
              <TableCell>{new Date(event.endDate).toLocaleString()}</TableCell>
              <TableCell align="right">
                <Button color="primary" onClick={() => navigate(`/admin/events/edit/${event._id}`)}>
                  Edit
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};

export default EventList;