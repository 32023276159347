import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, Grid, Card, CardContent, CardMedia, Button, Box } from '@mui/material';
import './CategoryPage.css';

const CategoryPage = () => {
  const { categoryName } = useParams();
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(`/api/events`);
        const filteredEvents = response.data.filter(event => event.category.toLowerCase() === categoryName.toLowerCase());
        setEvents(filteredEvents);
      } catch (error) {
        console.error('Error fetching events:', error.response ? error.response.data : error.message);
      }
    };

    fetchEvents();
  }, [categoryName]);

  return (
    <Container>
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" gutterBottom>{categoryName.charAt(0).toUpperCase() + categoryName.slice(1)} Events</Typography>
        <Grid container spacing={4}>
          {events.map(event => (
            <Grid item key={event._id} xs={12} sm={6} md={4}>
              <Card className="event-card">
                <CardMedia
                  component="img"
                  height="200"
                  image={`http://api.lericardo.pt:5000/uploads/${event.coverImage}`}
                  alt={event.title}
                  className="event-image"
                />
                <CardContent>
                  <Typography variant="h5" gutterBottom>{event.title}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {new Date(event.startDate).toLocaleString()}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {event.location}
                  </Typography>
                  <Button
                    component={Link}
                    to={`/event/${event._id}`}
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 2 }}
                    className="event-button"
                  >
                    View Details
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default CategoryPage;
