import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Typography, Box, Grid } from '@mui/material';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import { useAuth } from '../context/AuthContext';

// Register necessary components
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

const AdminAnalytics = () => {
  const { auth } = useAuth();
  const [totalGuests, setTotalGuests] = useState(0);
  const [guestDemographics, setGuestDemographics] = useState([]);
  const [ticketSales, setTicketSales] = useState([]);

  useEffect(() => {
    const fetchAnalytics = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${auth.token}`
          }
        };

        const totalGuestsResponse = await axios.get('/api/admin/analytics/total-guests', config);
        setTotalGuests(totalGuestsResponse.data.totalGuests);

        const demographicsResponse = await axios.get('/api/admin/analytics/guest-demographics', config);
        setGuestDemographics(demographicsResponse.data);

        const ticketSalesResponse = await axios.get('/api/admin/analytics/ticket-sales', config);
        setTicketSales(ticketSalesResponse.data);
      } catch (error) {
        console.error('Error fetching analytics data:', error.response ? error.response.data : error.message);
      }
    };

    fetchAnalytics();
  }, [auth.token]);

  const demographicsData = {
    labels: guestDemographics.map(d => d._id),
    datasets: [{
      label: 'Number of Guests',
      data: guestDemographics.map(d => d.count),
      backgroundColor: 'rgba(75, 192, 192, 0.2)',
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 1,
    }],
  };

  const ticketSalesData = {
    labels: ticketSales.map(sale => sale._id),
    datasets: [{
      label: 'Total Sales',
      data: ticketSales.map(sale => sale.totalSales),
      backgroundColor: 'rgba(153, 102, 255, 0.2)',
      borderColor: 'rgba(153, 102, 255, 1)',
      borderWidth: 1,
    }],
  };

  return (
    <Container>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h4" gutterBottom>Analytics Dashboard</Typography>
        <Typography variant="h6">Total Guests Registered: {totalGuests}</Typography>
        <Grid container spacing={4} sx={{ mt: 4 }}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Guest Demographics</Typography>
            <Bar data={demographicsData} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Ticket Sales</Typography>
            <Pie data={ticketSalesData} />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default AdminAnalytics;
