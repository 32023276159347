import React, { useState } from 'react';
import { Box, Button, Typography, CircularProgress } from '@mui/material';
import axios from 'axios';

const PaymentForm = ({ eventId, ticketType, name, phone, email, onPaymentSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { data: { paymentId, paymentUrl } } = await axios.post('/api/payments/create-payment', {
        ticketType,
        eventId,
        name,
        phone,
        email
      });

      window.location.href = paymentUrl;
    } catch (err) {
      console.error('Error during payment:', err);
      setError('Payment failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Typography variant="h6">Complete your payment with MB WAY</Typography>
      {error && <Typography color="error">{error}</Typography>}
      <Button type="submit" variant="contained" color="primary" disabled={loading} sx={{ mt: 2 }}>
        {loading ? <CircularProgress size={24} /> : 'Pay with MB WAY'}
      </Button>
    </Box>
  );
};

export default PaymentForm;