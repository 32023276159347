import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Drawer, CssBaseline, Toolbar, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PeopleIcon from '@mui/icons-material/People';
import EventIcon from '@mui/icons-material/Event';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GamesIcon from '@mui/icons-material/SportsEsports'; 
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useAuth } from '../context/AuthContext';
import LogoutIcon from '@mui/icons-material/Logout';
import BarChartIcon from '@mui/icons-material/BarChart';



const drawerWidth = 240;

const AdminMenu = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <div style={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: '#1976d2', // Blue background color
            color: 'white'
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar>
          <Typography variant="h6" noWrap>
            Admin Panel
          </Typography>
        </Toolbar>
        <List>
          <ListItem button onClick={() => navigate('/admin')}>
            <ListItemIcon><DashboardIcon style={{ color: 'white' }} /></ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem button onClick={() => navigate('/admin/users')}>
            <ListItemIcon><PeopleIcon style={{ color: 'white' }} /></ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
          <ListItem button onClick={() => navigate('/admin/events')}>
            <ListItemIcon><EventIcon style={{ color: 'white' }} /></ListItemIcon>
            <ListItemText primary="Events" />
          </ListItem>
          <ListItem button onClick={() => navigate('/admin/analytics')}>
            <ListItemIcon><BarChartIcon style={{ color: 'white' }} /></ListItemIcon>
            <ListItemText primary="Analytics" />
          </ListItem>
          <ListItem button onClick={() => navigate('/admin/games')}>
            <ListItemIcon><GamesIcon style={{ color: 'white' }} /></ListItemIcon>
            <ListItemText primary="Games" />
          </ListItem>
          <ListItem button onClick={() => navigate('/user/profile')}>
            <ListItemIcon><AccountCircleIcon style={{ color: 'white' }} /></ListItemIcon>
            <ListItemText primary="User Panel" />
          </ListItem>
        </List>
        <div style={{ flexGrow: 1 }} />
        <List>
          <ListItem button onClick={handleLogout}>
            <ListItemIcon><LogoutIcon style={{ color: 'white' }} /></ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
};

export default AdminMenu;