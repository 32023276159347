import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Typography, Box } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { useAuth } from '../context/AuthContext';

const PRAnalytics = () => {
  const { auth } = useAuth();
  const [guestData, setGuestData] = useState([]);
  const [eventData, setEventData] = useState([]);

  useEffect(() => {
    const fetchAnalyticsData = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${auth.token}`
          }
        };
        const guestResponse = await axios.get(`/api/pr/analytics/guests`, config);
        const eventResponse = await axios.get(`/api/pr/analytics/events`, config);

        setGuestData(guestResponse.data);
        setEventData(eventResponse.data);
      } catch (error) {
        console.error('Error fetching analytics data:', error.response ? error.response.data : error.message);
      }
    };

    fetchAnalyticsData();
  }, [auth.token]);

  const guestChartData = {
    labels: guestData.map(data => data.eventTitle),
    datasets: [
      {
        label: 'Number of Guests',
        data: guestData.map(data => data.count),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
    ],
  };

  const eventChartData = {
    labels: eventData.map(data => data.eventTitle),
    datasets: [
      {
        label: 'Number of Events',
        data: eventData.map(data => data.count),
        backgroundColor: 'rgba(153, 102, 255, 0.6)',
      },
    ],
  };

  return (
    <Container>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          PR Analytics
        </Typography>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            Number of Guests per Event
          </Typography>
          <Bar data={guestChartData} />
        </Box>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            Number of Events
          </Typography>
          <Bar data={eventChartData} />
        </Box>
      </Box>
    </Container>
  );
};

export default PRAnalytics;
