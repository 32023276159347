import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Typography, Box, Card, CardContent, Button, Grid, CardMedia, IconButton } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useNavigate } from 'react-router-dom';

const PRDashboard = () => {
  const { auth } = useAuth();
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${auth.token}`
          }
        };
        const response = await axios.get(`/api/pr/events`, config);
        console.log('Events response:', response.data); // Log the response
        if (Array.isArray(response.data)) {
          setEvents(response.data);
        } else {
          console.error('Expected an array of events but got:', response.data);
        }
      } catch (error) {
        console.error('Error fetching events:', error.response ? error.response.data : error.message);
      }
    };

    fetchEvents();
  }, [auth.token]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Link copied to clipboard!');
    }).catch((error) => {
      console.error('Error copying text:', error);
    });
  };

  const handleViewEvent = (eventId) => {
    navigate(`/pr/events/${eventId}`);
  };

  return (
    <Container>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h4" gutterBottom>My Events</Typography>
        <Grid container spacing={4}>
          {Array.isArray(events) && events.length > 0 ? (
            events.map(event => (
              <Grid item key={event._id} xs={12} sm={6} md={4}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <CardMedia
                    component="img"
                    height="200"
                    image={`http://api.lericardo.pt:5000/uploads/${event.coverImage}`}
                    alt={event.title}
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {event.title}
                    </Typography>
                    <Typography>
                      {event.description}
                    </Typography>
                  </CardContent>
                  <Box sx={{ p: 2, textAlign: 'center' }}>
                    <Button variant="contained" color="primary" sx={{ m: 1 }} onClick={() => handleViewEvent(event._id)}>
                      View Event Details
                    </Button>
                    <Button 
                      variant="contained" 
                      color="secondary" 
                      sx={{ m: 1 }} 
                      onClick={() => window.location.href = `${window.location.origin}/rpid/${auth.user.id}/event/${event._id}`}
                    >
                      RP Link
                    </Button>
                    <IconButton onClick={() => copyToClipboard(`${window.location.origin}/rpid/${auth.user.id}/event/${event._id}`)}>
                      <ContentCopyIcon />
                    </IconButton>
                  </Box>
                </Card>
              </Grid>
            ))
          ) : (
            <Typography variant="h6" color="textSecondary">
              No events found.
            </Typography>
          )}
        </Grid>
      </Box>
    </Container>
  );
};

export default PRDashboard;
