import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, Box, Card, CardMedia, CardContent, Grid, Avatar } from '@mui/material';
import { useAuth } from '../context/AuthContext';

const Dashboard = () => {
  const { auth } = useAuth();
  const [futureEvents, setFutureEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${auth.token}`
          }
        };
        const response = await axios.get('/api/admin/events', config);
        const events = response.data;
        const now = new Date();

        const future = events.filter(event => new Date(event.startDate) > now);
        const past = events.filter(event => new Date(event.startDate) <= now);

        setFutureEvents(future);
        setPastEvents(past);
      } catch (error) {
        console.error('Error fetching events:', error.response ? error.response.data : error.message);
      }
    };

    fetchEvents();
  }, [auth.token]);

  return (
    <Container>
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Dashboard
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Future Events
        </Typography>
        <Grid container spacing={4}>
          {futureEvents.map(event => (
            <Grid item key={event._id} xs={12} sm={6} md={4}>
              <Link to={`/admin/events/${event._id}`} style={{ textDecoration: 'none' }}>
                <Card sx={{ position: 'relative', overflow: 'visible' }}>
                  <Box sx={{ position: 'absolute', top: -30, left: 20, zIndex: 1 }}>
                    <Avatar 
                      src={`https://api.lericardo.pt/uploads/${event.clubLogo}`} 
                      alt={event.title}
                      sx={{ width: 60, height: 60, border: '2px solid white' }}
                    />
                  </Box>
                  <CardMedia
                    component="img"
                    height="140"
                    image={`https://api.lericardo.pt/uploads/${event.coverImage}`}
                    alt={event.title}
                  />
                  <CardContent>
                    <Typography variant="h6" component="h3">
                      {event.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {new Date(event.startDate).toLocaleString()}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {event.location}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>

        <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
          Past Events
        </Typography>
        <Grid container spacing={4}>
          {pastEvents.map(event => (
            <Grid item key={event._id} xs={12} sm={6} md={4}>
              <Link to={`/admin/events/${event._id}`} style={{ textDecoration: 'none' }}>
                <Card sx={{ position: 'relative', overflow: 'visible' }}>
                  <Box sx={{ position: 'absolute', top: -30, left: 20, zIndex: 1 }}>
                    <Avatar 
                      src={`https://api.lericardo.pt/uploads/${event.clubLogo}`} 
                      alt={event.title}
                      sx={{ width: 60, height: 60, border: '2px solid white' }}
                    />
                  </Box>
                  <CardMedia
                    component="img"
                    height="140"
                    image={`https://api.lericardo.pt/uploads/${event.coverImage}`}
                    alt={event.title}
                  />
                  <CardContent>
                    <Typography variant="h6" component="h3">
                      {event.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {new Date(event.startDate).toLocaleString()}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {event.location}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default Dashboard;