import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Typography, Container } from '@mui/material';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Confetti from 'react-confetti'; // Import the Confetti component
import './SpinningWheelGame.css';

const SpinWheelGame = () => {
    const { id } = useParams();
    const [wheel, setWheel] = useState(null);
    const [isSpinning, setIsSpinning] = useState(false);
    const [result, setResult] = useState(null);
    const [showConfetti, setShowConfetti] = useState(false); // State for confetti
    const wheelRef = useRef();

    useEffect(() => {
        const fetchWheel = async () => {
            try {
                const response = await axios.get(`/api/games/spinning-wheel/${id}`);
                setWheel(response.data);
            } catch (error) {
                console.error('Error fetching spinning wheel:', error.response ? error.response.data : error.message);
            }
        };

        fetchWheel();
    }, [id]);

    const spinWheel = async () => {
        if (isSpinning || !wheel) return;

        setIsSpinning(true);
        setResult(null);
        setShowConfetti(false); // Hide confetti before spinning

        try {
            const response = await axios.post(`/api/games/spin-wheel/${id}`);
            const { rotation, winningOption } = response.data;

            wheelRef.current.style.transition = `transform 5s ease-out`;
            wheelRef.current.style.transform = `rotate(${rotation}deg)`;

            setTimeout(() => {
                setResult(winningOption);
                setIsSpinning(false);
                setShowConfetti(true); // Show confetti when the wheel stops

            }, 5000);
        } catch (error) {
            console.error('Error spinning wheel:', error.response ? error.response.data : error.message);
            setIsSpinning(false);
        }
    };

    useEffect(() => {
        if (!isSpinning && result) {
            setTimeout(() => {
                wheelRef.current.style.transition = 'none';
                wheelRef.current.style.transform = 'rotate(0deg)';
            }, 1000);
        }
    }, [isSpinning, result]);

    return (
        <Container maxWidth="sm" sx={{ textAlign: 'center', mt: 4 }}>
                {showConfetti && <Confetti />} {/* Display confetti */}

            <Typography variant="h4" gutterBottom>Spinning Wheel</Typography>
            {wheel ? (
                <>
                    <Box sx={{ position: 'relative', height: '400px', width: '400px', margin: '0 auto' }}>
                        <div className="spinning-wheel" ref={wheelRef}>
                            {wheel.options.map((option, index) => (
                                <div
                                    key={index}
                                    className="wheel-sector"
                                    style={{
                                        transform: `rotate(${option.startAngle}deg) skewY(-${90 - (option.endAngle - option.startAngle)}deg)`,
                                        backgroundColor: index % 2 === 0 ? '#FFD700' : '#FF6347',
                                    }}
                                >
                                    <span style={{ transform: `skewY(${90 - (option.endAngle - option.startAngle)}deg) rotate(${(option.endAngle - option.startAngle) / 2}deg)` }}>
                                        {option.name}
                                    </span>
                                </div>
                            ))}
                            <div className="wheel-center">
                                <img src={`https://api.lericardo.pt/uploads/${wheel.eventId.clubLogo}`} alt="Event Logo" className="wheel-logo" />
                            </div>
                        </div>
                        <div className="spinning-wheel-arrow" style={{ top: '-20px', left: '50%', transform: 'translateX(-50%) rotate(180deg)' }} />
                    </Box>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={spinWheel}
                        disabled={isSpinning}
                        sx={{ mt: 4 }}
                    >
                        {isSpinning ? 'Spinning...' : 'Spin'}
                    </Button>
                    {result && (
                        <Typography variant="h5" sx={{ mt: 4 }}>
                            Congratulations! You won {result}!
                        </Typography>
                    )}
                </>
            ) : (
                <Typography>Loading...</Typography>
            )}
        </Container>
    );
};

export default SpinWheelGame;
