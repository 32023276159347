import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Card, CardContent, CardMedia, Grid, Button } from '@mui/material';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const UserQRs = () => {
  const { auth } = useAuth();
  const [qrCodes, setQrCodes] = useState([]);

  useEffect(() => {
    const fetchQRCodes = async () => {
      try {
        const response = await axios.get(`https://api.lericardo.pt/api/user/qr-codes`, {
          headers: { Authorization: `Bearer ${auth.token}` },
        });
        setQrCodes(response.data);
      } catch (error) {
        console.error('Error fetching QR codes:', error.response ? error.response.data : error.message);
      }
    };

    fetchQRCodes();
  }, [auth.token]);

  const downloadQRCode = (qrImage, eventTitle) => {
    const link = document.createElement('a');
    link.href = qrImage;
    link.download = `${eventTitle}-QR.png`;
    link.click();
  };

  return (
    <Container>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          Your QR Codes
        </Typography>
        <Grid container spacing={3}>
          {qrCodes.map((qrCode) => (
            <Grid item xs={12} sm={6} md={4} key={qrCode._id}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>{qrCode.eventId.title}</Typography>
                  <CardMedia
                    component="img"
                    height="200"
                    image={qrCode.qrImage}
                    alt={`QR Code for ${qrCode.eventId.title}`}
                    style={{ objectFit: 'contain' }}
                  />
                  <Typography variant="body2" color="textSecondary">
                    Event Date: {new Date(qrCode.eventId.startDate).toLocaleString()}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Expires At: {new Date(qrCode.expiresAt).toLocaleString()}
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <Button
                      component={Link}
                      to={`/event/${qrCode.eventId._id}`}
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      View Event Details
                    </Button>
                    <Button
                      onClick={() => downloadQRCode(qrCode.qrImage, qrCode.eventId.title)}
                      variant="outlined"
                      color="secondary"
                      fullWidth
                      sx={{ mt: 1 }}
                    >
                      Download QR Code
                    </Button>
                    <Button
                      component={Link}
                      to={`/ticket/${qrCode._id}`}
                      variant="contained"
                      color="secondary"
                      fullWidth
                      sx={{ mt: 1 }}
                    >
                      View Ticket
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default UserQRs;
