import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, Box, Card, CardContent, Button, Grid, List, ListItem, ListItemText, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useAuth } from '../context/AuthContext';

const PREventDetails = () => {
  const { eventId } = useParams();
  const { auth } = useAuth();
  const [event, setEvent] = useState(null);
  const [rps, setRps] = useState([]);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${auth.token}`
          }
        };
        const eventResponse = await axios.get(`/api/pr/events/${eventId}`, config);
        setEvent(eventResponse.data);

        const rpsResponse = await axios.get(`/api/pr/events/${eventId}/rps`, config);
        setRps(rpsResponse.data);
      } catch (error) {
        console.error('Error fetching event details:', error.response ? error.response.data : error.message);
      }
    };

    fetchEvent();
  }, [eventId, auth.token]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Link copied to clipboard!');
    }).catch((error) => {
      console.error('Error copying text:', error);
    });
  };

  if (!event) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Container>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h4" gutterBottom>{event.title}</Typography>
        <Card>
          <CardContent>
            <Typography variant="h6">Description:</Typography>
            <Typography variant="body1">{event.description}</Typography>
            <Typography variant="h6">Location:</Typography>
            <Typography variant="body1">{event.location}</Typography>
            <Typography variant="h6">Start Date:</Typography>
            <Typography variant="body1">{new Date(event.startDate).toLocaleString()}</Typography>
            <Typography variant="h6">End Date:</Typography>
            <Typography variant="body1">{new Date(event.endDate).toLocaleString()}</Typography>
            <Box sx={{ mt: 4 }}>
              <Typography variant="h6">Other Public Relations</Typography>
              <List>
                {rps.map(rp => (
                  <ListItem key={rp._id}>
                    <ListItemText primary={rp.name} secondary={rp.email} />
                    <IconButton onClick={() => copyToClipboard(`${window.location.origin}/rpid/${rp._id}/event/${event._id}`)}>
                      <ContentCopyIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default PREventDetails;
