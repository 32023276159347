import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Card, CardContent, CardMedia, Grid, Button, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './HomePage.css';
import CategoryCarousel from '../components/CategoryCarousel';
import SearchBar from '../components/SearchBar';


const HomePage = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(`/api/events`);
        setEvents(response.data);
      } catch (error) {
        console.error('Error fetching events:', error.response ? error.response.data : error.message);
      }
    };

    fetchEvents();
  }, []);

  return (
    <Container>
      <Box className="hero-section" sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h2" gutterBottom textAlign="center" className="hero-text">Discover Your Next Adventure</Typography>
        <Typography variant="h5" textAlign="center" className="hero-subtext">Find and book tickets for your favorite events</Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, mb: 4 }}>
        <SearchBar />
      </Box>
      <Typography variant="h4" gutterBottom>Categories</Typography>
      <CategoryCarousel />
      <Typography variant="h4" gutterBottom>Upcoming Events</Typography>
      <Grid container spacing={4}>
        {events.map((event) => (
          <Grid item key={event._id} xs={12} sm={6} md={4}>
            <Card className="event-card">
              <CardMedia
                component="img"
                height="200"
                image={`https://api.lericardo.pt/uploads/${event.coverImage}`}
                alt={event.title}
                className="event-image"
              />
              <CardContent>
                <Typography variant="h5" gutterBottom>{event.title}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {new Date(event.startDate).toLocaleString()}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {event.location}
                </Typography>
                <Button
                  component={Link}
                  to={`/event/${event._id}`}
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ mt: 2 }}
                  className="event-button"
                >
                  View Details
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default HomePage;
