import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Card, CardContent, CardMedia, Grid, Button, Tabs, Tab } from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';

const UserPanelHome = () => {
  const { auth } = useAuth();
  const [tickets, setTickets] = useState([]);
  const [value, setValue] = useState(0);

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await axios.get(`/api/user/qr-codes`, {
          headers: { Authorization: `Bearer ${auth.token}` },
        });
        setTickets(response.data);
      } catch (error) {
        console.error('Error fetching tickets:', error.response ? error.response.data : error.message);
      }
    };

    fetchTickets();
  }, [auth.token]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const filterTickets = (future = true) => {
    const now = new Date();
    return tickets.filter(ticket => future ? new Date(ticket.eventId.startDate) > now : new Date(ticket.eventId.endDate) < now);
  };

  return (
    <Container>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h4" gutterBottom>My Tickets</Typography>
        <Tabs value={value} onChange={handleChange} centered>
          <Tab label="Future Tickets" />
          <Tab label="Previous Tickets" />
        </Tabs>
        <Box sx={{ mt: 2 }}>
          {value === 0 && (
            <Grid container spacing={3}>
              {filterTickets(true).map(ticket => (
                <Grid item xs={12} sm={6} md={4} key={ticket._id}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>{ticket.eventId.title}</Typography>
                      <CardMedia
                        component="img"
                        height="200"
                        image={ticket.qrImage}
                        alt={`QR Code for ${ticket.eventId.title}`}
                        style={{ objectFit: 'contain' }}
                      />
                      <Typography variant="body2" color="textSecondary">
                        Event Date: {new Date(ticket.eventId.startDate).toLocaleString()}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Expires At: {new Date(ticket.expiresAt).toLocaleString()}
                      </Typography>
                      <Box sx={{ mt: 2 }}>
                        <Button
                          component={Link}
                          to={`/event/${ticket.eventId._id}`}
                          variant="contained"
                          color="primary"
                          fullWidth
                        >
                          View Event Details
                        </Button>
                        <Button
                          component={Link}
                          to={`/ticket/${ticket._id}`}
                          variant="contained"
                          color="secondary"
                          fullWidth
                          sx={{ mt: 1 }}
                        >
                          View Ticket
                        </Button>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
          {value === 1 && (
            <Grid container spacing={3}>
              {filterTickets(false).map(ticket => (
                <Grid item xs={12} sm={6} md={4} key={ticket._id}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>{ticket.eventId.title}</Typography>
                      <CardMedia
                        component="img"
                        height="200"
                        image={ticket.qrImage}
                        alt={`QR Code for ${ticket.eventId.title}`}
                        style={{ objectFit: 'contain' }}
                      />
                      <Typography variant="body2" color="textSecondary">
                        Event Date: {new Date(ticket.eventId.startDate).toLocaleString()}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Expires At: {new Date(ticket.expiresAt).toLocaleString()}
                      </Typography>
                      <Box sx={{ mt: 2 }}>
                        <Button
                          component={Link}
                          to={`/event/${ticket.eventId._id}`}
                          variant="contained"
                          color="primary"
                          fullWidth
                        >
                          View Event Details
                        </Button>
                        <Button
                          component={Link}
                          to={`/ticket/${ticket._id}`}
                          variant="contained"
                          color="secondary"
                          fullWidth
                          sx={{ mt: 1 }}
                        >
                          View Ticket
                        </Button>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default UserPanelHome;
