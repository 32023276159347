import React from 'react';
import { List, ListItem, ListItemText, Button } from '@mui/material';

const PublicRelationsList = ({ publicRelations, rpLinks, onCreateRPLink }) => {
  return (
    <List>
      {publicRelations.map(pr => {
        const rpLink = rpLinks.find(link => link.rpId === pr._id);
        return (
          <ListItem key={pr._id} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <ListItemText primary={`${pr.name} (${pr.email})`} secondary={rpLink ? rpLink.link : 'No link'} />
            {!rpLink && (
              <Button variant="contained" color="primary" onClick={() => onCreateRPLink(pr._id)}>
                Create Link
              </Button>
            )}
          </ListItem>
        );
      })}
    </List>
  );
};

export default PublicRelationsList;
