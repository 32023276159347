import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import PRMenu from '../components/PRMenu';

const PRLayout = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <PRMenu />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default PRLayout;
