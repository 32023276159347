import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Box, Card, CardContent, CardMedia, Grid, Divider, Paper } from '@mui/material';
import axios from 'axios';
import Countdown from 'react-countdown';

const TicketPage = () => {
  const { guestId } = useParams();
  const [guest, setGuest] = useState(null);
  const [event, setEvent] = useState(null);

  useEffect(() => {
    const fetchGuest = async () => {
      try {
        const response = await axios.get(`/api/guests/${guestId}`);
        setGuest(response.data);

        const eventResponse = await axios.get(`/api/events/${response.data.eventId}`);
        setEvent(eventResponse.data);
      } catch (error) {
        console.error('Error fetching guest or event:', error.response ? error.response.data : error.message);
      }
    };

    fetchGuest();
  }, [guestId]);

  if (!guest || !event) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Container>
      <Box sx={{ mt: 4, mb: 4 }}>
        <Paper elevation={3} sx={{ p: 3, background: '#f9f9f9', borderRadius: '15px' }}>
          <Typography variant="h4" gutterBottom textAlign="center" sx={{ fontWeight: 'bold', color: '#1976d2' }}>Event Ticket</Typography>
          <Typography variant="h5" gutterBottom textAlign="center" sx={{ fontWeight: 'bold' }}>{event.title}</Typography>
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            <Countdown date={new Date(event.startDate)} renderer={props => <Typography variant="h6" color="error">{props.days}d {props.hours}h {props.minutes}m {props.seconds}s</Typography>} />
          </Box>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={6} sx={{ textAlign: 'center' }}>
              <CardMedia
                component="img"
                height="200"
                image={guest.qrImage}
                alt="QR Code"
                sx={{ objectFit: 'contain', mx: 'auto', borderRadius: '10px', border: '1px solid #ddd' }}
              />
            </Grid>
          </Grid>
          <Divider sx={{ my: 2 }} />
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#1976d2' }}>Event Details</Typography>
            <Typography variant="body1" sx={{ fontWeight: '500' }}>Location: {event.location}</Typography>
            <Typography variant="body1" sx={{ fontWeight: '500' }}>Start Date: {new Date(event.startDate).toLocaleString()}</Typography>
            <Typography variant="body1" sx={{ fontWeight: '500', mb: 2 }}>End Date: {new Date(event.endDate).toLocaleString()}</Typography>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#1976d2' }}>Guest Information</Typography>
            <Typography variant="body1" sx={{ fontWeight: '500' }}>Name: {guest.name}</Typography>
            <Typography variant="body1" sx={{ fontWeight: '500' }}>Phone: {guest.phone}</Typography>
            <Typography variant="body1" sx={{ fontWeight: '500' }}>Email: {guest.email}</Typography>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default TicketPage;
