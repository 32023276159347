import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Drawer, CssBaseline, Toolbar, Typography, Divider, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import QrCodeIcon from '@mui/icons-material/QrCode';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from '../context/AuthContext';
import HomeIcon from '@mui/icons-material/Home';


const drawerWidth = 240;

const UserMenu = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <div style={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: '#1976d2', // Blue background color
            color: 'white'
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar>
          <Typography variant="h6" noWrap>
            User Panel
          </Typography>
        </Toolbar>
        <Divider />
        <List>
        <ListItem button onClick={() => navigate('/user')}>
            <ListItemIcon><HomeIcon style={{ color: 'white' }} /></ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem button onClick={() => navigate('/user/profile')}>
            <ListItemIcon><AccountBoxIcon style={{ color: 'white' }} /></ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItem>
          <ListItem button onClick={() => navigate('/user/qr-codes')}>
            <ListItemIcon><QrCodeIcon style={{ color: 'white' }} /></ListItemIcon>
            <ListItemText primary="QR Codes" />
          </ListItem>
        </List>
        <Box sx={{ flexGrow: 1 }} />
        <List>
          <Divider />
          <ListItem button onClick={handleLogout}>
            <ListItemIcon><LogoutIcon style={{ color: 'white' }} /></ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
};

export default UserMenu;