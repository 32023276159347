import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import './CookiesDisclaimer.css';

const CookiesDisclaimer = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (!cookiesAccepted) {
      setShow(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setShow(false);
  };

  const handleDecline = () => {
    localStorage.setItem('cookiesAccepted', 'false');
    setShow(false);
  };

  if (!show) {
    return null;
  }

  return (
    <Box className="cookies-disclaimer">
      <Typography variant="body2" sx={{ flexGrow: 1 }}>
        This website uses cookies to ensure you get the best experience on our website.
      </Typography>
      <Box>
        <Button variant="contained" color="primary" onClick={handleAccept}>
          Accept
        </Button>
        <Button variant="outlined" color="primary" onClick={handleDecline} sx={{ ml: 1 }}>
          Decline
        </Button>
      </Box>
    </Box>
  );
};

export default CookiesDisclaimer;
