import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, Box, Card, CardContent, Button, Snackbar, Alert } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import PublicRelationsList from './PublicRelationsList';

const EventDetails = () => {
  const { id } = useParams();
  const { auth } = useAuth();
  const [event, setEvent] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${auth.token}`
          }
        };
        const response = await axios.get(`/api/admin/events/${id}`, config);
        setEvent(response.data);
      } catch (error) {
        console.error('Error fetching event:', error.response ? error.response.data : error.message);
      }
    };

    fetchEvent();
  }, [auth.token, id]);

  const handleCreateRPLink = async (rpId) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`
        }
      };
      const response = await axios.post(`/api/admin/events/${id}/rp-links`, { rpId }, config);
      setSnackbarMessage('RP link created successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setEvent(prevEvent => ({
        ...prevEvent,
        rpLinks: [...prevEvent.rpLinks, response.data]
      }));
    } catch (error) {
      setSnackbarMessage(error.response?.data?.message || 'Operation failed!');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  if (!event) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Container>
      <Box sx={{ mt: 4 }}>
        <Card>
          <CardContent>
            <Typography variant="h4" gutterBottom>{event.title}</Typography>
            <Typography variant="h6">Description:</Typography>
            <Typography variant="body1">{event.description}</Typography>
            <Typography variant="h6">Location:</Typography>
            <Typography variant="body1">{event.location}</Typography>
            <Typography variant="h6">Start Date:</Typography>
            <Typography variant="body1">{new Date(event.startDate).toLocaleString()}</Typography>
            <Typography variant="h6">End Date:</Typography>
            <Typography variant="body1">{new Date(event.endDate).toLocaleString()}</Typography>
            <Typography variant="h6">Public Relations:</Typography>
            <PublicRelationsList
              publicRelations={event.publicRelations}
              rpLinks={event.rpLinks}
              onCreateRPLink={handleCreateRPLink}
            />
          </CardContent>
        </Card>
      </Box>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default EventDetails;
