import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Card, CardContent, CardMedia, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import 'swiper/swiper-bundle.css';
import './CategoryCarousel.css';

const categories = [
    { name: 'Disco', image: 'https://img.freepik.com/free-photo/cute-dj-woman-having-fun-playing-music-club-party_158595-2139.jpg?t=st=1722594025~exp=1722597625~hmac=5ae3c4cb2b2c944bd1e2aec48f01e65b6c58a18f61da1d5bac07c3b2c227c996&w=1380' },
    { name: 'Concert', image: 'https://img.freepik.com/free-photo/people-festival_1160-736.jpg?t=st=1722592979~exp=1722596579~hmac=cda9ee9cdc6f4d25b6866b53f5889ee727fa67f2e68e842d5fbd5fe78bfb8d68&w=1380' },
    { name: 'Festival', image: 'https://img.freepik.com/free-photo/young-people-enjoying-street-food_23-2151525727.jpg?t=st=1722594088~exp=1722597688~hmac=c353454580edc2f3fec07c1767a595a3a4811312fe28b0a71c17a808ac7b4c7c&w=1380' },
    { name: 'Comedy', image: 'https://img.freepik.com/free-photo/process-creating-stand-up-comedy_23-2151053481.jpg?t=st=1722594119~exp=1722597719~hmac=19e2ab7b4d6b1f9be695904091b214194a60104de08f1d938c9d1206f7780515&w=1380' },
    { name: 'After-party', image: 'https://img.freepik.com/free-photo/close-up-friends-dancing-together_23-2149119623.jpg?t=st=1722594139~exp=1722597739~hmac=13fb37d554ffe28c0f1d7aab9cee75e70801d4eb6b425a999d839bcb5ab2325a&w=1380' },
    { name: 'Sunset', image: 'https://img.freepik.com/free-photo/young-adults-enjoy-drinks-beach-party-generated-by-ai_188544-54210.jpg?t=st=1722594200~exp=1722597800~hmac=a21004bc87562daa0ace8533473ddd97f9073a88d00be8e9588a982e3ada947b&w=1380' },
];

const CategoryCarousel = () => {
    return (
      <Box sx={{ mt: 4, mb: 4 }}>
        <Swiper
          spaceBetween={20}
          slidesPerView={3.5}
          pagination={{ clickable: true }}
   
        >
          {categories.map(category => (
            <SwiperSlide key={category.name}>
             <Link to={`/category/${category.name.toLowerCase()}`} className="category-link">

              <Card className="category-card">
                <CardMedia
                  component="img"
                  height="200"
                  image={category.image}
                  alt={category.name}
                />
                <CardContent className="category-content">
                  <Typography variant="h5" gutterBottom>{category.name}</Typography>
                </CardContent>
              </Card>
              </Link>

            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    );
  };
  
  export default CategoryCarousel;