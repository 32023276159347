import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, Box, Card, CardContent, Button, Grid, CardMedia } from '@mui/material';
import { useAuth } from '../context/AuthContext';

const RPEvents = () => {
  const { rpid } = useParams();
  const [events, setEvents] = useState([]);
  const [prName, setPrName] = useState('');

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(`/api/events/rpid/${rpid}`);
        setEvents(response.data.events);
        setPrName(response.data.prName);
      } catch (error) {
        console.error('Error fetching events:', error.response ? error.response.data : error.message);
      }
    };

    fetchEvents();
  }, [rpid]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Typography variant="h2" component="h1" gutterBottom>
          Events by {prName}
        </Typography>
        <Typography variant="h6" color="textSecondary">
          Check out the events promoted by {prName}.
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {events.map(event => (
          <Grid item key={event._id} xs={12} sm={6} md={4}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia
                component="img"
                height="200"
                image={`http://api.lericardo.pt:5000/uploads/${event.coverImage}`}
                alt={event.title}
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="h5" component="h2">
                  {event.title}
                </Typography>
                <Typography>
                  {event.description}
                </Typography>
              </CardContent>
              <Box sx={{ p: 2, textAlign: 'center' }}>
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={() => window.location.href = `${window.location.origin}/rpid/${rpid}/event/${event._id}`}
                >
                  View Event
                </Button>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default RPEvents;
