import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Typography, Box, Card, CardContent, Button, Grid } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const CoordinatorDashboard = () => {
  const [events, setEvents] = useState([]);
  const { auth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${auth.token}`
          }
        };
        const response = await axios.get('/api/coordinator/events', config);
        setEvents(response.data);
      } catch (error) {
        console.error('Error fetching events:', error.response ? error.response.data : error.message);
      }
    };

    fetchEvents();
  }, [auth.token]);

  const handleViewEvent = (eventId) => {
    navigate(`/coordinator/event/${eventId}`);
  };

  return (
    <Container>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h4" gutterBottom>My Events</Typography>
        <Grid container spacing={4}>
          {events.map(event => (
            <Grid item key={event._id} xs={12} md={6} lg={4}>
              <Card>
                <CardContent>
                  <Typography variant="h5">{event.title}</Typography>
                  <Typography variant="body2">{event.description}</Typography>
                  <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={() => handleViewEvent(event._id)}>
                    View Event
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default CoordinatorDashboard;
