import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, TextField, Button, Typography, Box, Snackbar, Alert, MenuItem, Select, InputLabel, FormControl, Chip, Autocomplete } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import { useDropzone } from 'react-dropzone';
import { formatDateTimeLocal } from '../utils/dateUtils';

const categories = [
  'disco',
  'concert',
  'festival',
  'comedy',
  'after-party',
  'sunset',
];

const EventForm = ({ isEdit }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { auth } = useAuth();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [coordinator, setCoordinator] = useState('');
  const [category, setCategory] = useState('');
  const [publicRelations, setPublicRelations] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [coverImage, setCoverImage] = useState(null);
  const [coverImageUrl, setCoverImageUrl] = useState(null);
  const [clubLogo, setClubLogo] = useState(null);
  const [clubLogoUrl, setClubLogoUrl] = useState(null);
  const [flyerImage, setFlyerImage] = useState(null);
  const [flyerImageUrl, setFlyerImageUrl] = useState(null);
  const [venueMap, setVenueMap] = useState(null);
  const [venueMapUrl, setVenueMapUrl] = useState(null);
  const [tickets, setTickets] = useState([{ name: '', price: '', quantity: '' }]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${auth.token}`
          }
        };
        const response = await axios.get('/api/admin/users', config);
        setAllUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error.response ? error.response.data : error.message);
      }
    };

    const fetchEvent = async () => {
      if (isEdit && id) {
        try {
          const config = {
            headers: {
              Authorization: `Bearer ${auth.token}`
            }
          };
          const response = await axios.get(`/api/admin/events/${id}`, config);
          const event = response.data;
          setTitle(event.title);
          setDescription(event.description);
          setLocation(event.location);
          setStartDate(formatDateTimeLocal(event.startDate));
          setEndDate(formatDateTimeLocal(event.endDate));
          setCoordinator(event.coordinator);
          setPublicRelations(event.publicRelations);
          setCategory(event.category);
          setTickets(event.tickets || []);  // Load tickets here
          if (event.coverImage) setCoverImageUrl(`https://api.lericardo.pt/uploads/${event.coverImage}`);
          if (event.clubLogo) setClubLogoUrl(`https://api.lericardo.pt/uploads/${event.clubLogo}`);
          if (event.flyerImage) setFlyerImageUrl(`https://api.lericardo.pt/uploads/${event.flyerImage}`);
          if (event.venueMap) setVenueMapUrl(`https://api.lericardo.pt/uploads/${event.venueMap}`);
        } catch (error) {
          console.error('Error fetching event:', error.response ? error.response.data : error.message);
        }
      }
    };

    fetchUsers();
    fetchEvent();
  }, [auth.token, isEdit, id]);

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      switch (name) {
        case 'coverImage':
          setCoverImage(files[0]);
          setCoverImageUrl(URL.createObjectURL(files[0]));
          break;
        case 'clubLogo':
          setClubLogo(files[0]);
          setClubLogoUrl(URL.createObjectURL(files[0]));
          break;
        case 'flyerImage':
          setFlyerImage(files[0]);
          setFlyerImageUrl(URL.createObjectURL(files[0]));
          break;
        case 'venueMap':
          setVenueMap(files[0]);
          setVenueMapUrl(URL.createObjectURL(files[0]));
          break;
        default:
          break;
      }
    }
  };

  const handleAddTicket = () => {
    setTickets([...tickets, { name: '', price: '', quantity: '' }]);
  };

  const handleRemoveTicket = (index) => {
    const newTickets = [...tickets];
    newTickets.splice(index, 1);
    setTickets(newTickets);
  };

  const handleTicketChange = (index, field, value) => {
    const newTickets = [...tickets];
    newTickets[index][field] = value;
    setTickets(newTickets);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('location', location);
    formData.append('startDate', startDate);
    formData.append('endDate', endDate);
    formData.append('coordinator', coordinator);
    formData.append('category', category);
  
    // Here we make sure to append each `ObjectId` from the `publicRelations` array directly.
    publicRelations.forEach((pr, index) => {
      formData.append(`publicRelations[${index}]`, pr._id || pr);
    });
  
    // Convert tickets to a JSON string and append to formData
    formData.append('tickets', JSON.stringify(tickets));
  
    // Append files to the formData if they exist
    if (coverImage) {
      formData.append('coverImage', coverImage);
    }
    if (clubLogo) {
      formData.append('clubLogo', clubLogo);
    }
    if (flyerImage) {
      formData.append('flyerImage', flyerImage);
    }
    if (venueMap) {
      formData.append('venueMap', venueMap);
    }
  
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          'Content-Type': 'multipart/form-data'
        }
      };
  
      if (isEdit) {
        await axios.put(`/api/admin/events/${id}`, formData, config);
        setSnackbarMessage('Event updated successfully!');
      } else {
        await axios.post('/api/admin/events', formData, config);
        setSnackbarMessage('Event created successfully!');
      }
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setTimeout(() => navigate('/admin/events'), 1500);
    } catch (error) {
      setSnackbarMessage(error.response?.data?.message || 'Operation failed!');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };
  
  
  

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handlePublicRelationsChange = async (event, value) => {
    setPublicRelations(value.map(v => v._id));

    // Automatically create RP link for newly added PRs
    if (isEdit) {
      const newPRs = value.filter(pr => !publicRelations.includes(pr._id));
      for (const pr of newPRs) {
        try {
          const config = {
            headers: {
              Authorization: `Bearer ${auth.token}`
            }
          };
          await axios.post(`/api/admin/events/${id}/rp-links`, { rpId: pr._id }, config);
        } catch (error) {
          console.error('Error creating RP link:', error.response ? error.response.data : error.message);
        }
      }
    }
  };

  const Dropzone = ({ title, onDrop, file, fileUrl }) => {
    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      accept: 'image/jpeg, image/png, image/gif'
    });

    return (
      <Box {...getRootProps()} sx={{
        border: '2px dashed #1976d2',
        borderRadius: '5px',
        padding: '16px',
        textAlign: 'center',
        cursor: 'pointer',
        margin: '16px 0',
        width: '100%'
      }}>
        <input {...getInputProps()} />
        <Typography variant="body1">{title}</Typography>
        {fileUrl && (
          <Box mt={2}>
            <img src={fileUrl} alt="Preview" style={{ maxHeight: '200px', maxWidth: '100%' }} />
          </Box>
        )}
        {file && (
          <Box mt={2}>
            <img src={URL.createObjectURL(file)} alt="Preview" style={{ maxHeight: '200px', maxWidth: '100%' }} />
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Container>
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          {isEdit ? 'Edit Event' : 'Create Event'}
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <TextField
              label="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              margin="normal"
              required
              fullWidth
            />
            <TextField
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              margin="normal"
              required
              fullWidth
            />
            <TextField
              label="Location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              margin="normal"
              required
              fullWidth
            />
            <TextField
              label="Start Date"
              type="datetime-local"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              margin="normal"
              required
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="End Date"
              type="datetime-local"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              margin="normal"
              required
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel id="coordinator-label">Coordinator</InputLabel>
              <Select
                labelId="coordinator-label"
                value={coordinator}
                onChange={(e) => setCoordinator(e.target.value)}
                required
              >
                {allUsers.filter(user => user.role === 'coordinator').map(user => (
                  <MenuItem key={user._id} value={user._id}>{user.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Autocomplete
              multiple
              options={allUsers.filter(user => user.role === 'public_relations')}
              getOptionLabel={(option) => option.name}
              onChange={handlePublicRelationsChange}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip key={option._id} label={option.name} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Public Relations"
                  placeholder="Add PR"
                />
              )}
              sx={{ width: '100%', margin: '16px 0' }}
            />
            <TextField
              label="Category"
              name="category"
              select
              fullWidth
              margin="normal"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              required
            >
              {categories.map(category => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </TextField>
            <Dropzone title="Cover Image" onDrop={(acceptedFiles) => {
              setCoverImage(acceptedFiles[0]);
              setCoverImageUrl(URL.createObjectURL(acceptedFiles[0]));
            }} file={coverImage} fileUrl={coverImageUrl} />
            <Dropzone title="Club Logo" onDrop={(acceptedFiles) => {
              setClubLogo(acceptedFiles[0]);
              setClubLogoUrl(URL.createObjectURL(acceptedFiles[0]));
            }} file={clubLogo} fileUrl={clubLogoUrl} />
            <Dropzone title="Flyer Image" onDrop={(acceptedFiles) => {
              setFlyerImage(acceptedFiles[0]);
              setFlyerImageUrl(URL.createObjectURL(acceptedFiles[0]));
            }} file={flyerImage} fileUrl={flyerImageUrl} />
            <Dropzone title="Venue Map" onDrop={(acceptedFiles) => {
              setVenueMap(acceptedFiles[0]);
              setVenueMapUrl(URL.createObjectURL(acceptedFiles[0]));
            }} file={venueMap} fileUrl={venueMapUrl} />

            {/* Tickets Section */}
            <Box sx={{ mt: 4, width: '100%' }}>
              <Typography variant="h6" gutterBottom>Tickets</Typography>
              {tickets.map((ticket, index) => (
                <Box key={index} sx={{ display: 'flex', gap: 2, mb: 2 }}>
                  <TextField
                    label={`Ticket Name ${index + 1}`}
                    value={ticket.name}
                    onChange={(e) => handleTicketChange(index, 'name', e.target.value)}
                    required
                    fullWidth
                  />
                  <TextField
                    label={`Price ${index + 1}`}
                    type="number"
                    value={ticket.price}
                    onChange={(e) => handleTicketChange(index, 'price', e.target.value)}
                    required
                    fullWidth
                  />
                  <TextField
                    label={`Quantity ${index + 1}`}
                    type="number"
                    value={ticket.quantity}
                    onChange={(e) => handleTicketChange(index, 'quantity', e.target.value)}
                    required
                    fullWidth
                  />
                  <Button onClick={() => handleRemoveTicket(index)} color="error">Delete</Button>
                </Box>
              ))}
              <Button onClick={handleAddTicket} variant="contained" color="secondary">Add Ticket</Button>
            </Box>

            <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
              {isEdit ? 'Update Event' : 'Create Event'}
            </Button>
          </Box>
        </form>
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
};

export default EventForm;
