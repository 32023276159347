import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, Box, Card, CardMedia, CardContent, TextField, Button, MenuItem, Stepper, Step, StepLabel, Snackbar, Alert } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from '../components/PaymentForm';

const stripePromise = loadStripe('ricardo');

const PublicEventPage = () => {
  const { id, rpid } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [dob, setDob] = useState('');
  const [email, setEmail] = useState('');
  const [ticketType, setTicketType] = useState('');
  const [registrationLink, setRegistrationLink] = useState(window.location.href);
  const [activeStep, setActiveStep] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [guestId, setGuestId] = useState(null);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await axios.get(`/api/events/${id}`);
        setEvent(response.data);
      } catch (error) {
        console.error('Error fetching event:', error.response ? error.response.data : error.message);
      }
    };

    fetchEvent();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (event.tickets.find(ticket => ticket.type === ticketType && ticket.price > 0)) {
      setActiveStep(1);
    } else {
      await registerGuest();
    }
  };

  const registerGuest = async () => {
    const guestData = { name, phone, dob, email, registrationLink, ticketType };
    try {
      const response = await axios.post(`/api/events/${id}/guests`, guestData);
      navigate(`/qr/${response.data._id}`);
    } catch (error) {
      setSnackbarMessage(error.response ? error.response.data : 'Error submitting guest data');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handlePaymentSuccess = async () => {
    await registerGuest();
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  if (!event) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Container>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h4" gutterBottom>{event.title}</Typography>
        <Card>
          <CardMedia
            component="img"
            height="200"
            image={`https://api.lericardo.pt/uploads/${event.coverImage}`}
            alt={event.title}
          />
          <CardContent>
            <Typography variant="h6">Description:</Typography>
            <Typography variant="body1">{event.description}</Typography>
            <Typography variant="h6">Location:</Typography>
            <Typography variant="body1">{event.location}</Typography>
            <Typography variant="h6">Start Date:</Typography>
            <Typography variant="body1">{new Date(event.startDate).toLocaleString()}</Typography>
            <Typography variant="h6">End Date:</Typography>
            <Typography variant="body1">{new Date(event.endDate).toLocaleString()}</Typography>
            <Box sx={{ mt: 4 }}>
              <Typography variant="h6">Register for Guest List</Typography>
              <Stepper activeStep={activeStep} alternativeLabel>
                <Step>
                  <StepLabel>Details</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Payment</StepLabel>
                </Step>
              </Stepper>
              {activeStep === 0 && (
                <Box component="form" onSubmit={handleSubmit}>
                  <TextField 
                    label="Name" 
                    fullWidth 
                    margin="normal" 
                    value={name} 
                    onChange={(e) => setName(e.target.value)} 
                    required 
                  />
                  <TextField 
                    label="Phone" 
                    fullWidth 
                    margin="normal" 
                    value={phone} 
                    onChange={(e) => setPhone(e.target.value)} 
                    required 
                  />
                  <TextField 
                    label="Date of Birth" 
                    type="date" 
                    fullWidth 
                    margin="normal" 
                    value={dob} 
                    onChange={(e) => setDob(e.target.value)} 
                    InputLabelProps={{ shrink: true }} 
                    required 
                  />
                  <TextField 
                    label="Email" 
                    type="email" 
                    fullWidth 
                    margin="normal" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                    required 
                  />
                  <TextField
                    label="Select Ticket"
                    name="ticketType"
                    select
                    fullWidth
                    margin="normal"
                    value={ticketType}
                    onChange={(e) => setTicketType(e.target.value)}
                    required
                  >
                    {event.tickets.map((ticket) => (
                      <MenuItem key={ticket.type} value={ticket.type}>
                        {`${ticket.type} - €${ticket.price}`}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
                    Continue
                  </Button>
                </Box>
              )}
              {activeStep === 1 && (
                <Elements stripe={stripePromise}>
                  <PaymentForm 
                    eventId={id}
                    ticketType={ticketType}
                    name={name}
                    phone={phone}
                    email={email}
                    onPaymentSuccess={handlePaymentSuccess}
                  />
                </Elements>
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default PublicEventPage;
