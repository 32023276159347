import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, Box } from '@mui/material';
import QRCode from 'qrcode.react';

const QRCodePage = () => {
  const { guestId } = useParams();
  const [guest, setGuest] = useState(null);

  useEffect(() => {
    const fetchGuest = async () => {
      try {
        const response = await axios.get(`/api/guests/${guestId}`);
        setGuest(response.data);
      } catch (error) {
        console.error('Error fetching guest data:', error.response ? error.response.data : error.message);
      }
    };

    fetchGuest();
  }, [guestId]);

  if (!guest) {
    return <Typography>Loading...</Typography>;
  }

  const qrData = JSON.stringify({
    name: guest.name,
    phone: guest.phone,
    dob: guest.dob,
    email: guest.email,
    eventId: guest.eventId
  });

  return (
    <Container>
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Typography variant="h4" gutterBottom>Guest QR Code</Typography>
        <QRCode value={qrData} size={256} />
        <Typography variant="body1" sx={{ mt: 2 }}>
          Please present this QR code at the event.
        </Typography>
      </Box>
    </Container>
  );
};

export default QRCodePage;