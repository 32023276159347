import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const ProtectedRoute = ({ children, redirectTo }) => {
  const { auth } = useAuth();
  console.log('ProtectedRoute auth:', auth);
  return auth ? children : <Navigate to={redirectTo} />;
};

export default ProtectedRoute;