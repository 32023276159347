import React from 'react';
import 'swiper/swiper-bundle.css';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import AdminRoute from './components/AdminRoute';
import ProtectedRoute from './components/ProtectedRoute';
import HomePage from './pages/HomePage';
import UserList from './components/UserList';
import UserForm from './components/UserForm';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import AdminLayout from './pages/AdminLayout';
import UserLayout from './pages/UserLayout';
import EventList from './components/EventList';
import EventForm from './components/EventForm';
import Dashboard from './components/Dashboard';
import EventDetails from './components/EventDetails';
import PublicEventPage from './components/PublicEventPage';
import QRCodePage from './components/QRCodePage';
import UserProfile from './pages/UserProfile';
import UserQRs from './pages/UserQRs';
import UserPanelHome from './pages/UserPanelHome';
import Menu from './components/Menu';
import Footer from './components/Footer';
import TicketPage from './pages/TicketPage';
import CategoryPage from './pages/CategoryPage';
import SearchResults from './pages/SearchResults';
import CookiesDisclaimer from './components/CookiesDisclaimer';
import CoordinatorDashboard from './pages/CoordinatorDashboard';
import CoordinatorLayout from './pages/CoordinatorLayout';
import PRDashboard from './pages/PRDashboard';
import PRLayout from './pages/PRLayout';
import RPEvents from './pages/RPEvents';
import AdminAnalytics from './pages/AdminAnalytics';
import PRAnalytics from './pages/PRAnalytics';
import PREventDetails from './pages/PREventDetails';
import Games from './pages/Games';
import SpinWheelForm from './components/SpinWheelForm';
import SpinWheelGame from './pages/SpinWheelGame';


const App = () => {
  const location = useLocation();
  const isPublicPage = location.pathname === '/' || location.pathname.startsWith('/event') || location.pathname.startsWith('/search') || location.pathname.startsWith('/game') || location.pathname.startsWith('/category');

  return (
    <>
      {isPublicPage && <Menu />}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/event/:id" element={<PublicEventPage />} />
        <Route path="/qr/:guestId" element={<QRCodePage />} />
        <Route path="/ticket/:guestId" element={<TicketPage />} />
        <Route path="/category/:categoryName" element={<CategoryPage />} />
        <Route path="/search" element={<SearchResults />} />
        <Route path="/rpid/:rpid/event/:id" element={<PublicEventPage />} /> {/* RP event registration */}
        <Route path="/game/spinning-wheel/:id" element={<SpinWheelGame />} />


        <Route path="/admin" element={<AdminRoute><AdminLayout /></AdminRoute>}>
          <Route index element={<Dashboard />} />
          <Route path="users" element={<UserList />} />
          <Route path="users/create" element={<UserForm isEdit={false} />} />
          <Route path="users/edit/:id" element={<UserForm isEdit={true} />} />
          <Route path="events" element={<EventList />} />
          <Route path="events/create" element={<EventForm isEdit={false} />} />
          <Route path="events/edit/:id" element={<EventForm isEdit={true} />} />
          <Route path="events/:id" element={<EventDetails />} />
          <Route path="analytics" element={<AdminAnalytics />} /> 
          <Route path="games" element={<Games />} />
          <Route path="games/spinning-wheel" element={<SpinWheelForm />} />
          <Route path="/admin/games/spinning-wheel/:id" element={<SpinWheelForm />} />
        </Route>
        <Route path="/user" element={<ProtectedRoute redirectTo="/login"><UserLayout /></ProtectedRoute>}>
          <Route path="profile" element={<UserProfile />} />
          <Route path="qr-codes" element={<UserQRs />} />
          <Route index element={<UserPanelHome />} />
        </Route>
        <Route path="/coordinator" element={<ProtectedRoute redirectTo="/login"><CoordinatorLayout /></ProtectedRoute>}>
          <Route index element={<CoordinatorDashboard />} />
          <Route path="events/:id" element={<EventDetails />} />
        </Route>
        <Route path="/pr" element={<ProtectedRoute redirectTo="/login"><PRLayout /></ProtectedRoute>}>
          <Route index element={<PRDashboard />} />
          <Route path="events/:eventId" element={<PREventDetails />} />
          <Route path="/pr/analytics" element={<ProtectedRoute redirectTo="/login"><PRAnalytics /></ProtectedRoute>} />
        </Route>

        <Route path="/rpid/:rpid" element={<RPEvents />} />
      </Routes>
      <Footer />
      <CookiesDisclaimer />
    </>
  );
};

const AppWrapper = () => (
  <BrowserRouter>
    <AuthProvider>
      <App />
    </AuthProvider>
  </BrowserRouter>
);

export default AppWrapper;
