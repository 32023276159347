import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const Menu = () => {
  const { auth, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
         <Link  style={{textDecoration: 'none', color: 'white'}} to="/">Event Guest App</Link>
        </Typography>

        {!auth ? (
          <>
            <Button color="inherit" component={Link} to="/login">Login</Button>
            <Button color="inherit" component={Link} to="/register">Register</Button>
          </>
        ) : (
          <>
            {auth.role === 'admin' && (
              <Button color="inherit" component={Link} to="/admin">
                Admin Panel
              </Button>
            )}
            {auth.role === 'coordinator' && (
              <Button color="inherit" component={Link} to="/coordinator">
                Coordinator Panel
              </Button>
            )}
            {auth.role === 'user' && (
              <Button color="inherit" component={Link} to="/user">
                User Panel
              </Button>
            )}
            {auth.role === 'public_relations' && (
              <Button color="inherit" component={Link} to="/pr">
                PR Panel
              </Button>
            )}
            <Button color="inherit" onClick={handleLogout}>Logout</Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Menu;
