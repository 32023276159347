import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { Container, Typography, Box, Button, TextField, Select, MenuItem, FormControl, InputLabel, Snackbar, Alert, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const SpinWheelForm = () => {
  const { id } = useParams(); // Retrieve the id from the URL
  const { auth } = useAuth();
  const [futureEvents, setFutureEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [eventDetails, setEventDetails] = useState(null);
  const [wheelOptions, setWheelOptions] = useState([{ name: '', percentage: '' }]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [gameLink, setGameLink] = useState('');

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${auth.token}`
          }
        };
        const response = await axios.get('/api/admin/events', config);
        const events = response.data;
        const now = new Date();

        const future = events.filter(event => new Date(event.startDate) > now);
        setFutureEvents(future);
      } catch (error) {
        console.error('Error fetching events:', error.response ? error.response.data : error.message);
      }
    };

    const fetchWheel = async () => {
      if (id) {
        try {
          const config = {
            headers: {
              Authorization: `Bearer ${auth.token}`
            }
          };
          const response = await axios.get(`/api/games/spinning-wheel/${id}`, config);
          const wheel = response.data;
          setSelectedEvent(wheel.eventId);
          setWheelOptions(wheel.options);
          setGameLink(`${window.location.origin}/game/spinning-wheel/${wheel._id}`);

          // Fetch event details for display
          const eventResponse = await axios.get(`/api/events/${wheel.eventId._id}`, config);
          setEventDetails(eventResponse.data);
        } catch (error) {
          console.error('Error fetching spinning wheel:', error.response ? error.response.data : error.message);
        }
      }
    };

    fetchEvents();
    fetchWheel(); // Fetch the existing spinning wheel data if in edit mode
  }, [auth.token, id]);

  const handleAddOption = () => {
    setWheelOptions([...wheelOptions, { name: '', percentage: '' }]);
  };

  const handleOptionChange = (index, field, value) => {
    const newOptions = [...wheelOptions];
    newOptions[index][field] = value;

    // Automatically calculate the remaining percentage
    if (field === 'percentage') {
      const totalPercentage = newOptions.reduce((sum, option) => sum + (parseFloat(option.percentage) || 0), 0);
      if (totalPercentage > 100) {
        setSnackbarMessage('Total percentage exceeds 100%!');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }
    }

    setWheelOptions(newOptions);
  };

  const handleDeleteOption = (index) => {
    const newOptions = wheelOptions.filter((_, i) => i !== index);
    setWheelOptions(newOptions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const totalPercentage = wheelOptions.reduce((sum, option) => sum + parseFloat(option.percentage), 0);

    if (totalPercentage !== 100) {
      setSnackbarMessage('Total percentage must be exactly 100%');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`
        }
      };

      let response;
      if (id) {
        // Edit mode: Update the existing spinning wheel
        response = await axios.put(`/api/games/spinning-wheel/${id}`, { eventId: selectedEvent, options: wheelOptions }, config);
        setSnackbarMessage('Spinning Wheel updated successfully!');
      } else {
        // Create mode: Create a new spinning wheel
        response = await axios.post('/api/games/spinning-wheel', { eventId: selectedEvent, options: wheelOptions }, config);
        setGameLink(response.data.link);
        setSnackbarMessage('Spinning Wheel created successfully!');
      }
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error saving spinning wheel:', error.response ? error.response.data : error.message);
      setSnackbarMessage('Failed to save Spinning Wheel');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h4" gutterBottom>{id ? 'Edit Spinning Wheel' : 'Create Spinning Wheel'}</Typography>
        <form onSubmit={handleSubmit}>
          {id ? (
            <Box sx={{ mb: 2 }}>
              <Typography variant="h6">Event: {eventDetails?.title}</Typography>
              <Typography variant="body1">{eventDetails ? new Date(eventDetails.startDate).toLocaleString() : ''}</Typography>
            </Box>
          ) : (
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="event-select-label">Select Future Event</InputLabel>
              <Select
                labelId="event-select-label"
                value={selectedEvent}
                onChange={(e) => setSelectedEvent(e.target.value)}
                required
              >
                {futureEvents.map(event => (
                  <MenuItem key={event._id} value={event._id}>
                    {event.title} - {new Date(event.startDate).toLocaleString()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <Box sx={{ mb: 2 }}>
            {wheelOptions.map((option, index) => (
              <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                <TextField
                  label={`Option ${index + 1}`}
                  value={option.name}
                  onChange={(e) => handleOptionChange(index, 'name', e.target.value)}
                  required
                  fullWidth
                />
                <TextField
                  label={`Percentage ${index + 1}`}
                  type="number"
                  value={option.percentage}
                  onChange={(e) => handleOptionChange(index, 'percentage', e.target.value)}
                  required
                  fullWidth
                />
                <IconButton onClick={() => handleDeleteOption(index)} color="error">
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
            <Button onClick={handleAddOption} variant="contained" color="secondary">Add Option</Button>
          </Box>

          <Button type="submit" variant="contained" color="primary">{id ? 'Update Spinning Wheel' : 'Create Spinning Wheel'}</Button>
        </form>
        {gameLink && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6">Game Link:</Typography>
            <a href={gameLink} target="_blank" rel="noopener noreferrer">{gameLink}</a>
            <Button onClick={() => navigator.clipboard.writeText(gameLink)}>Copy to Clipboard</Button>
          </Box>
        )}
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
};

export default SpinWheelForm;
